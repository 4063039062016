<template>
  <div class="ribbon-two-box">
    <div class="ribbon-two"><span :class="classChosen()">{{title}}</span></div>
    <div class="ribbon-content">
      <div class="main-grid">
        <slot name="main" class="main"></slot>
      </div>
      <div class="assistant-grid">
        <slot name="assistant-grid"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RibbonTWo",
  props: {
    title: {
      type: String,
      default: ""
    },
    color: {
      type: String,
      default: "primary"
    },
  },
  data() {
    return {

    }
  },
  methods: {
    classChosen() {
      switch (this.color) {
        case "info": return "ribbon-two-info";
        case "primary": return "ribbon-two-primary";
        case "dark": return "ribbon-two-dark";
        case "success": return "ribbon-two-success";
        case "warning": return "ribbon-two-warning";
        case "danger": return "ribbon-two-danger";
        case "pink": return "ribbon-two-pink";
      }
    }
  }
}
</script>


<style lang="scss">
.ribbon-two-box {
  position: relative;
  border-radius: 4px;
  margin-bottom: 10px;
  background: #ffffff;
  .ribbon-two {
    position: absolute;
    left: -5px;
    top: -5px;
    z-index: 1;
    overflow: hidden;
    width: 75px;
    height: 75px;
    text-align: right;
    span {
      font-size: 13px;
      color: #fff;
      text-align: center;
      line-height: 20px;
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
      width: 100px;
      display: block;
      box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.02);
      position: absolute;
      top: 19px;
      left: -21px;
      font-weight: 600;
    }
    span:before {
      content: "";
      position: absolute;
      left: 0;
      top: 100%;
      z-index: -1;
      border-right: 3px solid transparent;
      border-bottom: 3px solid transparent;
    }
    span:after {
      content: "";
      position: absolute;
      right: 0;
      top: 100%;
      z-index: -1;
      border-left: 3px solid transparent;
      border-bottom: 3px solid transparent;
    }
  }
  .ribbon-two-primary {
    background: #188ae2;
  }
  .ribbon-two-primary:before {
    border-left: 3px solid #11609d;
    border-top: 3px solid #11609d;
  }
  .ribbon-two-primary:after {
    border-right: 3px solid #11609d;
    border-top: 3px solid #11609d;
  }
  .ribbon-two-dark {
    background: #414d5f;
  }
  .ribbon-two-dark:before {
    border-left: 3px solid #222832;
    border-top: 3px solid #222832;
  }
  .ribbon-two-dark:after {
    border-right: 3px solid #222832;
    border-top: 3px solid #222832;
  }
  .ribbon-two-info {
    background: #35b8e0;
  }
  .ribbon-two-info:before {
    border-left: 3px solid #1b8bae;
    border-top: 3px solid #1b8bae;
  }
  .ribbon-two-info:after {
    border-right: 3px solid #1b8bae;
    border-top: 3px solid #1b8bae;
  }
  .ribbon-two-success {
    background: #31ce77;
  }
  .ribbon-two-success:before {
    border-left: 3px solid #229053;
    border-top: 3px solid #229053;
  }
  .ribbon-two-success:after {
    border-right: 3px solid #229053;
    border-top: 3px solid #229053;
  }
  .ribbon-two-warning {
    background: #fbcc5c;
  }
  .ribbon-two-warning:before {
    border-left: 3px solid #f9b617;
    border-top: 3px solid #f9b617;
  }
  .ribbon-two-warning:after {
    border-right: 3px solid #f9b617;
    border-top: 3px solid #f9b617;
  }
  .ribbon-two-danger {
    background: #f34943;
  }
  .ribbon-two-danger:before {
    border-left: 3px solid #db150e;
    border-top: 3px solid #db150e;
  }
  .ribbon-two-danger:after {
    border-right: 3px solid #db150e;
    border-top: 3px solid #db150e;
  }
  .ribbon-two-pink {
    background: #ff679b;
  }
  .ribbon-two-pink:before {
    border-left: 3px solid #af466b;
    border-top: 3px solid #af466b;
  }
  .ribbon-two-pink:after {
    border-right: 3px solid #af466b;
    border-top: 3px solid #af466b;
  }
}
</style>
