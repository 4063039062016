<template>
  <div>
    <div class="home-bg">
      <vue-particles
        class="home-particles"
        color="#fff"
        :particleOpacity="0.7"
        :particlesNumber="80"
        shapeType="circle"
        :particleSize="5"
        linesColor="#fff"
        :linesWidth="1"
        :lineLinked="true"
        :lineOpacity="0.4"
        :linesDistance="150"
        :moveSpeed="2"
        :hoverEffect="true"
        hoverMode="grab"
        :clickEffect="true"
        clickMode="push"
      ></vue-particles>
      <div class="home-title">张家港市特种设备贴码工作信息公示平台</div>
    </div>
    <div class="home-center">
      <div class="one-unit">
        <div class="unit-img unit-img-1"></div>
        <div class="unit-text">
          <div class="num">{{ info.mainTotalNum ? info.mainTotalNum : 0 }}</div>
          <div class="info">待黏贴</div>
        </div>
      </div>
      <div class="one-unit">
        <div class="unit-img unit-img-2"></div>
        <div class="unit-text">
          <div class="num">
            {{ info.mainPastedNum ? info.mainPastedNum : 0 }}
          </div>
          <div class="info">已黏贴</div>
        </div>
      </div>
      <div class="one-unit">
        <div class="unit-img unit-img-3"></div>
        <div class="unit-text">
          <div class="num">{{ info.mainTodayNum ? info.mainTodayNum : 0 }}</div>
          <div class="info">今日黏贴</div>
        </div>
      </div>
    </div>
    <el-row class="home-query" v-show="flag === 2">
      <el-col :sm="{ span: 7, offset: 8 }" :xs="{ span: 15, offset: 4 }">
        <el-input
          v-model="useCompany"
          placeholder="企业名称"
          size="medium"
        ></el-input>
      </el-col>
      <el-col :sm="{ span: 1 }" :xs="{ span: 1 }">
        <el-button
          icon="el-icon-search"
          size="medium"
          circle
          type="primary"
          @click="getByBranchOffice"
        ></el-button>
      </el-col>
    </el-row>
    <el-row class="home-table">
      <el-col :sm="{ span: 16, offset: 4 }" :xs="{ span: 24 }">
        <transition name="el-zoom-in-center" mode="out-in">
          <el-table
            :data="info.list"
            v-if="flag === 1"
            v-loading="loading"
            border
            @row-click="getByBranchOffice"
            key="father"
          >
            <el-table-column
              prop="supervisoryOrgan"
              label="分局"
              width="300"
            ></el-table-column>
            <el-table-column prop="totalNum" label="待黏贴"></el-table-column>
            <el-table-column prop="pastedNum" label="已黏贴"></el-table-column>
            <el-table-column prop="todayNum" label="今日黏贴"></el-table-column>
            <el-table-column label="贴码率" width="300">
              <tamplate slot-scope="scope">
                <el-progress
                  :text-inside="true"
                  :stroke-width="24"
                  v-show="
                    Math.floor(
                      (+scope.row.pastedNum / +scope.row.totalNum) * 100
                    ) >= 50
                  "
                  :percentage="
                    Math.floor(
                      (+scope.row.pastedNum / +scope.row.totalNum) * 100
                    )
                  "
                ></el-progress>
                <el-progress
                  :text-inside="true"
                  :stroke-width="24"
                  v-show="
                    Math.floor(
                      (+scope.row.pastedNum / +scope.row.totalNum) * 100
                    ) < 50
                  "
                  :percentage="
                    Math.floor(
                      (+scope.row.pastedNum / +scope.row.totalNum) * 100
                    )
                  "
                  status="exception"
                ></el-progress>
              </tamplate>
            </el-table-column>
          </el-table>
          <el-table
            :data="info.list"
            v-if="flag === 2"
            v-loading="loading"
            border
            key="children"
            @row-click="getCompanyPasteCode"
          >
            <el-table-column prop="useCompany" label="企业名称" width="300">
              <template slot="header">
                <span>企业名称</span>
                <font-awesome-icon
                  :icon="['fas', 'arrow-left']"
                  style="color: #3699ff; font-size: 14px"
                  @click="back"
                />
              </template>
            </el-table-column>
            <el-table-column prop="totalNum" label="待黏贴"></el-table-column>
            <el-table-column prop="pastedNum" label="已黏贴"></el-table-column>
            <el-table-column prop="todayNum" label="今日黏贴"></el-table-column>
            <el-table-column label="贴码率" width="300">
              <tamplate slot-scope="scope">
                <el-progress
                  :text-inside="true"
                  :stroke-width="24"
                  v-if="
                    Math.floor(
                      (+scope.row.pastedNum / +scope.row.totalNum) * 100
                    ) >= 100
                  "
                  :percentage="
                    Math.floor(
                      (+scope.row.pastedNum / +scope.row.totalNum) * 100
                    )
                  "
                  status="success"
                ></el-progress>
                <el-progress
                  :text-inside="true"
                  :stroke-width="24"
                  v-else-if="
                    Math.floor(
                      (+scope.row.pastedNum / +scope.row.totalNum) * 100
                    ) < 50
                  "
                  :percentage="
                    Math.floor(
                      (+scope.row.pastedNum / +scope.row.totalNum) * 100
                    )
                  "
                  status="exception"
                ></el-progress>
                <el-progress
                  :text-inside="true"
                  :stroke-width="24"
                  v-else
                  :percentage="
                    Math.floor(
                      (+scope.row.pastedNum / +scope.row.totalNum) * 100
                    )
                  "
                ></el-progress>
              </tamplate>
            </el-table-column>
          </el-table>
          <el-table
            :data="info"
            v-if="flag === 3"
            border
            key="grandson"
            v-loading="loading"
          >
            <el-table-column prop="useCompany" label="公司" width="300">
              <template slot="header">
                <span>公司</span>
                <font-awesome-icon
                  :icon="['fas', 'arrow-left']"
                  style="color: #3699ff; font-size: 14px"
                  @click="back"
                />
              </template>
              <template slot-scope="scope">
                <div class="useCompany-pic">
                  <div>{{ scope.row.useCompany }}</div>
                  <div class="new-pic" v-show="scope.row.isNew === 1"></div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="fileNum"
              label="档案号"
              min-width="200"
            ></el-table-column>
            <el-table-column
              prop="pastDate"
              label="张贴日期"
              min-width="200"
            ></el-table-column>
            <el-table-column
              prop="usePlace"
              label="使用地点"
              width="300"
            ></el-table-column>
            <el-table-column
              prop="derviceStatus"
              label="状态"
              width="100"
            ></el-table-column>
            <el-table-column
              prop="equipmentType"
              label="设备类别"
              min-width="200"
            ></el-table-column>
            <el-table-column
              prop="useRegisterNum"
              label="注册代码"
              min-width="200"
            ></el-table-column>
            <el-table-column
              prop="registrationDate"
              label="使用登记日期"
              min-width="200"
            ></el-table-column>
          </el-table>
        </transition>
        <my-pagination
          v-show="total > 0"
          :total="total"
          v-if="flag === 3"
          :page.sync="queryParams.pageNum"
          :limit.sync="queryParams.pageSize"
          @pagination="getList"
        />
      </el-col>
    </el-row>
    <div class="el-login-footer">
      <a href="https://beian.miit.gov.cn/#/Integrated/index">
        苏ICP备20001396号
      </a>
      <div>Copyright © 2019-2021 Imtenrent. All Rights Reserved.</div>
    </div>
  </div>
</template>

<script>
import tsg from "@/views/mixins/tsg.js";
import {
  getPastInfo,
  getByBranchOffice,
  getCompanyPasteCode
} from "@/api/menu.js";

export default {
  name: "postCode",
  mixins: [tsg],
  data() {
    return {
      info: {},
      flag: 1,
      useCompany: "",
      supervisoryOrgan: "",
      queryParams: {
        useCompany: "",
        pageNum: 1,
        pageSize: 10
      },
      total: 0,
      loading: false
    };
  },
  computed: {},
  mounted() {
    this.getPastInfo();
  },
  methods: {
    // 右边人员接口
    getPastInfo() {
      this.loading = true;
      getPastInfo().then(response => {
        this.info = response.data.data;
        this.loading = false;
      });
    },
    getByBranchOffice(row) {
      if (row) {
        if (row.supervisoryOrgan) {
          this.supervisoryOrgan = row.supervisoryOrgan;
        }
      }
      this.loading = true;
      getByBranchOffice({
        supervisoryOrgan: this.supervisoryOrgan,
        useCompany: this.useCompany
      }).then(response => {
        this.flag = 2;
        this.info = response.data.data;
        this.loading = false;
      });
    },
    getList() {
      this.loading = true;
      getCompanyPasteCode(this.queryParams).then(response => {
        this.flag = 3;
        this.info = response.data.rows;
        this.total = response.data.total;
        this.loading = false;
      });
    },
    getCompanyPasteCode(row) {
      this.queryParams.useCompany = row.useCompany;
      this.loading = true;
      getCompanyPasteCode(this.queryParams).then(response => {
        this.flag = 3;
        this.info = response.data.rows;
        this.total = response.data.total;
        this.loading = false;
      });
    },
    back() {
      this.flag = this.flag - 1;
      if (this.flag === 1) {
        this.getPastInfo();
      } else {
        this.getByBranchOffice();
      }
    }
  }
};
</script>

<style lang="scss">
@media screen and (max-width: 768px) {
  .home-bg {
    height: 26vw;
    background: url(~@/assets/images/bg_index.png) no-repeat center;
    .home-particles {
      height: 26vw;
    }
    .home-title {
      font-family: YouSheBiaoTiHei;
      font-size: 5vw;
      text-align: center;
      height: 26vw;
      line-height: 26vw;
      transform: translateY(-26vw);
    }
  }
  .home-center {
    width: 62vw;
    height: 14vw;
    background: #ffffff;
    border-radius: 5px;
    box-shadow: 0 0 10px #888888;
    margin: 0 auto;
    margin-top: -4vw;
    display: flex;
    justify-content: space-around;
    align-items: center;
    .one-unit {
      width: 18vw;
      height: 6vw;
      display: flex;
      color: #4f81c7;
      .unit-img {
        width: 6vw;
        height: 6vw;
      }
      .unit-img-1 {
        background: url(~@/assets/img/1.png) no-repeat center/100%;
      }
      .unit-img-2 {
        background: url(~@/assets/img/2.png) no-repeat center/100%;
      }
      .unit-img-3 {
        background: url(~@/assets/img/3.png) no-repeat center/100%;
      }
      .unit-text {
        width: 12vw;
        height: 6vw;
        .num {
          font-size: 2.5vw;
          font-family: pangMenZhengDao;
          text-align: center;
          height: 3vw;
          line-height: 3vw;
        }
        .info {
          font-size: 2.2vw;
          text-align: center;
          height: 3vw;
          line-height: 3vw;
          color: #999999;
        }
      }
    }
  }
}
@media screen and (min-width: 768px) {
  .home-bg {
    height: 12vw;
    background: url(~@/assets/images/bg_index.png) no-repeat center/100% 100%;
    .home-particles {
      height: 12vw;
    }
    .home-title {
      font-family: YouSheBiaoTiHei;
      font-size: 3.5vw;
      text-align: center;
      height: 12vw;
      line-height: 12vw;
      transform: translateY(-12vw);
    }
  }
  .home-center {
    width: 60vw;
    height: 8vw;
    background: #ffffff;
    border-radius: 5px;
    box-shadow: 0 0 10px #888888;
    margin: 0 auto;
    margin-top: -3vw;
    display: flex;
    justify-content: space-around;
    align-items: center;
    .one-unit {
      width: 12vw;
      height: 4vw;
      display: flex;
      color: #4f81c7;
      .unit-img {
        width: 4vw;
        height: 4vw;
      }
      .unit-img-1 {
        background: url(~@/assets/img/1.png) no-repeat center/100%;
      }
      .unit-img-2 {
        background: url(~@/assets/img/2.png) no-repeat center/100%;
      }
      .unit-img-3 {
        background: url(~@/assets/img/3.png) no-repeat center/100%;
      }
      .unit-text {
        width: 8vw;
        height: 4vw;
        .num {
          font-size: 1.5vw;
          font-family: pangMenZhengDao;
          text-align: center;
          height: 2vw;
          line-height: 2vw;
        }
        .info {
          font-size: 1.2vw;
          text-align: center;
          height: 2vw;
          line-height: 2vw;
          color: #999999;
        }
      }
    }
  }
}
.el-login-footer {
  height: 40px;
  line-height: 20px;
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
  color: rgb(184, 48, 48);
  font-family: Arial;
  font-size: 12px;
  letter-spacing: 1px;
}
.home-query {
  margin: 10px 0;
}
.home-table {
  margin: 10px 0;
}
.useCompany-pic {
  display: flex;
  .new-pic {
    width: 30px;
    height: 30px;
    background: {
      image: url("~@/assets/img/new.png");
      repeat: no-repeat;
      size: 100%;
    }
  }
}
</style>
