import request from '@/utils/request'

// 获取路由
export function getPastInfo() {
    return request({
        url: '/tsg/baseInfo/mainPage/getPastInfo',
        method: 'get'
    });
}

export function getByBranchOffice(data) {
    return request({
        url: '/tsg/baseInfo/mainPage/getByBranchOffice',
        method: 'get',
        params: data
    });
}

export function getCompanyPasteCode(data) {
    return request({
        url: '/tsg/baseInfo/mainPage/getCompanyPasteCode',
        method: 'get',
        params: data
    });
}