import request from "@/utils/request";

export function getCompanySign(query) {
  return request({
    url: "/tsg/baseInfo/tsgMaintenanceContract/reportCompanySign",
    method: "get",
    params: query
  });
}

export function getBySupervisoryOrgan(query) {
  return request({
    url: "/tsg/baseInfo/tsgMaintenanceContract/reportBySupervisoryOrgan",
    method: "get",
    params: query
  });
}

export function getContractInfo() {
  return request({
    url: "/tsg/baseInfo/tsgMaintenanceContract/reportContractList",
    method: "get"
  });
}
