import request from "@/utils/request";
import Carbox from "@/myComponents/Carbox";
import NoDataList from "@/myComponents/NoDataList";
import MyPagination from "@/myComponents/MyPagination";
import Ribbon from "@/myComponents/Ribbon";
import RibbonTwo from "@/myComponents/RibbonTwo";

export default {
  components: { Carbox, MyPagination, NoDataList, Ribbon, RibbonTwo },
  data() {
    return {
      loading: false,
      queryParams: {
        pageNum: 1,
        pageSize: 5
      },
      dataList: [],
      total: 0
    };
  },
  created() {
    this.handleQuery();
  },
  methods: {
    getList() {
      this.loading = true;
      request({
        url: this.url,
        method: "get",
        params: this.queryParams
      }).then(response => {
        this.dataList = response.rows;
        this.total = response.total;
        this.loading = false;
      });
    },
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    }
  }
};
