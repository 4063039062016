<template>
  <div class="no-data-list">
    <div class="zwsj">
      <img src="@/assets/images/zwsj.png" />
    </div>
    <div class="no-data-title">暂无数据</div>
  </div>
</template>

<script>
import * as animationData from "@/assets/lottie/noData.json";

// 第二步：使用组件
export default {
  name: "noDataList",
  data() {
    return {
      defaultOptions: { animationData: animationData },
      animationSpeed: 1,
      anim: {}
    };
  },
  methods: {
    handleAnimation: function(anim) {
      this.anim = anim;
      console.log(anim); //这里可以看到 lottie 对象的全部属性
    }
  }
};
</script>

<style lang="scss">
.no-data-list {
  background-color: #fff;
  height: 29.375rem;
  .zwsj {
    padding: 0.625rem;
    display: flex;
    justify-content: center;
  }
  .no-data-title {
    text-align: center;
    color: #aab2b6;
  }
}
</style>
