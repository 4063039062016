<template>
  <div>
    <div class="home-bg">
      <vue-particles
        class="home-particles"
        color="#fff"
        :particleOpacity="0.7"
        :particlesNumber="80"
        shapeType="circle"
        :particleSize="5"
        linesColor="#fff"
        :linesWidth="1"
        :lineLinked="true"
        :lineOpacity="0.4"
        :linesDistance="150"
        :moveSpeed="2"
        :hoverEffect="true"
        hoverMode="grab"
        :clickEffect="true"
        clickMode="push"
      ></vue-particles>
      <div class="home-title">张家港市特种设备认证公示平台</div>
    </div>
    <div class="home-center">
      <div class="one-unit">
        <div class="unit-img unit-img-1"></div>
        <div class="unit-text">
          <div class="num">{{ truckNum }}</div>
          <div class="info">叉车总数量</div>
        </div>
      </div>
      <div class="one-unit">
        <div class="unit-img unit-img-2"></div>
        <div class="unit-text">
          <div class="num">
            {{ info.mainSignNum ? info.mainSignNum : 0 }}
          </div>
          <div class="info">认证数量</div>
        </div>
      </div>
      <div class="one-unit">
        <div class="unit-img unit-img-3"></div>
        <div class="unit-text">
          <div class="num">
            {{ info.mainInstallNum ? info.mainInstallNum : 0 }}
          </div>
          <div class="info">安装数量</div>
        </div>
      </div>
    </div>
    <el-row class="home-query" v-show="flag === 2">
      <el-col :sm="{ span: 7, offset: 8 }" :xs="{ span: 15, offset: 4 }">
        <el-input
          v-model="useCompany"
          placeholder="企业名称"
          size="medium"
        ></el-input>
      </el-col>
      <el-col :sm="{ span: 1 }" :xs="{ span: 1 }">
        <el-button
          icon="el-icon-search"
          size="medium"
          circle
          type="primary"
          @click="getByBranchOffice"
        ></el-button>
      </el-col>
    </el-row>

    <el-row class="home-table">
      <el-col :sm="{ span: 22, push: 1, pull: 1 }" :xs="{ span: 24 }">
        <transition name="el-zoom-in-center" mode="out-in">
          <el-table
            :data="info.list"
            v-if="flag === 1"
            v-loading="loading"
            border
            @row-click="getByBranchOffice"
            key="father"
          >
            <el-table-column
              prop="supervisoryOrgan"
              label="分局"
              width="300"
            ></el-table-column>
            <el-table-column
              prop="totalNum"
              label="叉车数"
              width="80"
            ></el-table-column>
            <el-table-column
              prop="signNum"
              label="已认证数"
              width="80"
            ></el-table-column>
            <el-table-column
              prop="notSignNum"
              label="未认证数"
              width="80"
            ></el-table-column>
            <el-table-column
              prop="installNum"
              label="已安装数"
              width="80"
            ></el-table-column>
            <el-table-column
              prop="notInstallNum"
              label="未安装数"
              width="80"
            ></el-table-column>
            <el-table-column label="已认证未安装数">
              <template slot-scope="scope">
                <span>{{ scope.row.signNum - scope.row.installNum }}</span>
              </template>
            </el-table-column>
            <el-table-column label="认证率" width="280">
              <template slot-scope="scope">
                <el-progress
                  :text-inside="true"
                  :stroke-width="24"
                  v-show="scope.row.signRate >= 50"
                  :percentage="
                    scope.row.signRate > 100 ? 100 : scope.row.signRate
                  "
                ></el-progress>
                <el-progress
                  :text-inside="true"
                  :stroke-width="24"
                  v-show="scope.row.signRate < 50"
                  :percentage="scope.row.signRate"
                  status="exception"
                ></el-progress>
              </template>
            </el-table-column>
            <el-table-column label="安装率" width="280">
              <template slot-scope="scope">
                <el-progress
                  :text-inside="true"
                  :stroke-width="24"
                  v-show="scope.row.installRate >= 50"
                  :percentage="
                    scope.row.installRate > 100 ? 100 : scope.row.installRate
                  "
                ></el-progress>
                <el-progress
                  :text-inside="true"
                  :stroke-width="24"
                  v-show="scope.row.installRate < 50"
                  :percentage="scope.row.installRate"
                  status="exception"
                ></el-progress>
              </template>
            </el-table-column>
          </el-table>
          <el-table
            :data="info.list"
            v-if="flag === 2"
            v-loading="loading"
            border
            height="600"
            key="children"
            @row-click="handleView"
            :row-style="rowStyle"
          >
            <el-table-column prop="useCompany" label="企业名称" width="300">
              <template slot="header">
                <span>企业名称</span>
                <font-awesome-icon
                  :icon="['fas', 'arrow-left']"
                  style="color: #3699ff; font-size: 14px"
                  @click="back"
                />
              </template>
            </el-table-column>
            <el-table-column
              prop="totalNum"
              label="叉车数"
              width="80"
            ></el-table-column>
            <el-table-column
              prop="signNum"
              label="已认证数"
              width="80"
            ></el-table-column>
            <el-table-column
              prop="notSignNum"
              label="未认证数"
              width="80"
            ></el-table-column>
            <el-table-column
              prop="installNum"
              label="已安装数"
              width="80"
            ></el-table-column>
            <el-table-column
              prop="notInstallNum"
              label="未安装数"
              width="80"
            ></el-table-column>
            <el-table-column label="已认证未安装数">
              <template slot-scope="scope">
                <span>{{ scope.row.signNum - scope.row.installNum }}</span>
              </template>
            </el-table-column>
            <el-table-column label="认证率" width="250">
              <template slot-scope="scope">
                <el-progress
                  :text-inside="true"
                  :stroke-width="24"
                  v-show="scope.row.signRate >= 50"
                  :percentage="
                    scope.row.signRate > 100 ? 100 : scope.row.signRate
                  "
                ></el-progress>
                <el-progress
                  :text-inside="true"
                  :stroke-width="24"
                  v-show="scope.row.signRate < 50"
                  :percentage="scope.row.signRate"
                  status="exception"
                ></el-progress>
              </template>
            </el-table-column>

            <el-table-column label="安装率" width="250">
              <template slot-scope="scope">
                <el-progress
                  :text-inside="true"
                  :stroke-width="24"
                  v-show="scope.row.installRate >= 50"
                  :percentage="
                    scope.row.installRate > 100 ? 100 : scope.row.installRate
                  "
                ></el-progress>
                <el-progress
                  :text-inside="true"
                  :stroke-width="24"
                  v-show="scope.row.installRate < 50"
                  :percentage="scope.row.installRate"
                  status="exception"
                ></el-progress>
              </template>
            </el-table-column>
          </el-table>
        </transition>
        <application-dialog ref="applicationDialog"></application-dialog>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { selectSignInfo, getBySupervisoryOrgan } from "@/api/signStatistics.js";
import applicationDialog from "./signManage/applicationDialog.vue";

export default {
  name: "signStatistics",
  data() {
    return {
      info: {},
      flag: 1,
      useCompany: "",
      supervisoryOrgan: "",
      queryParams: {
        useCompany: "",
        pageNum: 1,
        pageSize: 10
      },
      total: 0,
      loading: false,
      truckNum: 0
    };
  },
  components: { applicationDialog },
  mounted() {
    this.selectSignInfo();
  },
  methods: {
    rowStyle(row) {
      let that = row.row;
      let stylejson = {};
      if (that.notSignNum < 0) {
        stylejson.color = "orange";
        return stylejson;
      } else if (this.flag == 2 && that.otherSignNum > 0) {
        stylejson.color = "orange";
        return stylejson;
      } else {
        return "";
      }
    },
    selectSignInfo() {
      this.loading = true;
      selectSignInfo().then(response => {
        this.info = response.data.data;
        this.truckNum = this.info.mainTotalNum ? this.info.mainTotalNum : 0;
        this.loading = false;
      });
    },
    getByBranchOffice(row) {
      if (row) {
        if (row.supervisoryOrgan) {
          this.supervisoryOrgan = row.supervisoryOrgan;
          this.truckNum = row.totalNum;
        }
      }
      this.loading = true;
      getBySupervisoryOrgan({
        supervisoryOrgan: this.supervisoryOrgan,
        useCompany: this.useCompany
      }).then(response => {
        this.flag = 2;
        this.info = response.data.data;
        this.loading = false;
      });
    },
    back() {
      this.flag = this.flag - 1;
      if (this.flag === 1) {
        this.selectSignInfo();
      } else {
        this.getByBranchOffice();
      }
    },
    handleView(item) {
      this.$refs.applicationDialog.openDialog(item.signId, item.useCompany);
    }
  }
};
</script>

<style lang="scss">
@media screen and (max-width: 768px) {
  .home-bg {
    height: 26vw;
    background: url(~@/assets/images/bg_index.png) no-repeat center;
    .home-particles {
      height: 26vw;
    }
    .home-title {
      font-family: YouSheBiaoTiHei;
      font-size: 2.5vw !important;
      text-align: center;
      height: 26vw;
      line-height: 26vw;
      transform: translateY(-26vw);
      color: #ffff;
    }
  }
  .home-center {
    width: 62vw;
    height: 14vw;
    background: #ffffff;
    border-radius: 5px;
    box-shadow: 0 0 10px #888888;
    margin: 0 auto;
    margin-top: -4vw;
    display: flex;
    justify-content: space-around;
    align-items: center;
    .one-unit {
      width: 18vw;
      height: 6vw;
      display: flex;
      color: #4f81c7;
      .unit-img {
        width: 6vw;
        height: 6vw;
      }
      .unit-img-1 {
        background: url(~@/assets/img/1.png) no-repeat center/100%;
      }
      .unit-img-2 {
        background: url(~@/assets/img/2.png) no-repeat center/100%;
      }
      .unit-img-3 {
        background: url(~@/assets/img/3.png) no-repeat center/100%;
      }
      .unit-text {
        width: 12vw;
        height: 6vw;
        .num {
          font-size: 2.5vw;
          font-family: pangMenZhengDao;
          text-align: center;
          height: 3vw;
          line-height: 3vw;
        }
        .info {
          font-size: 0.8vw;
          text-align: center;
          height: 3vw;
          line-height: 3vw;
          color: #999999;
        }
      }
    }
  }
}
@media screen and (min-width: 768px) {
  .home-bg {
    height: 12vw;
    background: url(~@/assets/images/bg_index.png) no-repeat center/100% 100%;
    .home-particles {
      height: 12vw;
    }
    .home-title {
      font-family: YouSheBiaoTiHei;
      font-size: 2.5vw !important;
      text-align: center;
      height: 12vw;
      line-height: 12vw;
      transform: translateY(-12vw);
      color: #ffff;
    }
  }
  .home-center {
    width: 60vw;
    height: 8vw;
    background: #ffffff;
    border-radius: 5px;
    box-shadow: 0 0 10px #888888;
    margin: 0 auto;
    margin-top: -3vw;
    display: flex;
    justify-content: space-around;
    align-items: center;
    .one-unit {
      width: 12vw;
      height: 4vw;
      display: flex;
      color: #4f81c7;
      .unit-img {
        width: 4vw;
        height: 4vw;
      }
      .unit-img-1 {
        background: url(~@/assets/img/1.png) no-repeat center/100%;
      }
      .unit-img-2 {
        background: url(~@/assets/img/2.png) no-repeat center/100%;
      }
      .unit-img-3 {
        background: url(~@/assets/img/3.png) no-repeat center/100%;
      }
      .unit-text {
        width: 8vw;
        height: 4vw;
        .num {
          font-size: 1.5vw;
          font-family: pangMenZhengDao;
          text-align: center;
          height: 2vw;
          line-height: 2vw;
        }
        .info {
          font-size: 1.2vw;
          text-align: center;
          height: 2vw;
          line-height: 2vw;
          color: #999999;
        }
      }
    }
  }
}
.home-query {
  margin: 10px 0;
}
.home-table {
  margin: 15px 0;
}
.useCompany-pic {
  display: flex;
  .new-pic {
    width: 30px;
    height: 30px;
    background: {
      image: url("~@/assets/img/new.png");
      repeat: no-repeat;
      size: 100%;
    }
  }
}
</style>
