<template>
  <div>
    <div class="home-bg">
      <vue-particles class="home-particles" color="#fff" :particleOpacity="0.7" :particlesNumber="80" shapeType="circle" :particleSize="5" linesColor="#fff" :linesWidth="1" :lineLinked="true" :lineOpacity="0.4" :linesDistance="150" :moveSpeed="2" :hoverEffect="true" hoverMode="grab" :clickEffect="true" clickMode="push">
      </vue-particles>
      <div class="home-title">{{flag=='1'? '张家港市特种设备使用单位双重预防机制落实公示平台(总览)':'张家港市特种设备使用单位双重预防机制落实公示平台(分局总览)'}}</div>
    </div>
    <div class="home-center">
      <div class="one-unit">
        <div class="unit-img unit-img-1">
        </div>
        <div class="unit-text">
          <div class="num">{{info.totalNum?info.totalNum:0}}</div>
          <div class="info">使用单位数量</div>
        </div>
      </div>
      <div class="one-unit">
        <div class="unit-img unit-img-2">
        </div>
        <div class="unit-text">
          <div class="num">{{info.finishNum?info.finishNum:0}}</div>
          <div class="info">已落实数量</div>
        </div>
      </div>
      <div class="one-unit">
        <div class="unit-img unit-img-3">
        </div>
        <div class="unit-text">
          <div class="num">{{info.unfinishNum?info.unfinishNum:0}}</div>
          <div class="info">待落实数量</div>
        </div>
      </div>
      <div class="one-unit">
        <div class="unit-img unit-img-3">
        </div>
        <div class="unit-text">
          <div class="num">{{info.rate&&!isNaN(info.rate)?info.rate:0}}%</div>
          <div class="info">落实率</div>
        </div>
      </div>
    </div>
    <el-row class="home-query" v-show="flag === 2">
      <el-col :sm={span:7,offset:8} :xs={span:15,offset:4}>
        <el-input v-model="searchKey" placeholder="企业名称" size="medium">
        </el-input>
      </el-col>
      <el-col :sm={span:1} :xs={span:1}>
        <el-button icon="el-icon-search" size="medium" circle type="primary" @click="clickSearch">
        </el-button>
      </el-col>
    </el-row>
    <el-row class="home-table">
      <el-col :sm={span:20,offset:2} :xs={span:24}>
        <transition name="el-zoom-in-center" mode="out-in">
          <el-table :data="info.list" v-loading="loading" border @row-click="getItemList" key="father" v-if="flag===1" fit='true'>
            <el-table-column prop="supervisoryOrgan" label="分局" width="300">
            </el-table-column>
            <el-table-column prop="totalNum" label="使用单位数量">
            </el-table-column>
            <el-table-column prop="finishNum" label="已落实企业">
            </el-table-column>
            <el-table-column prop="unfinishNum" label="待落实企业">
            </el-table-column>
            <el-table-column label="进度">
              <template slot-scope="scope">
                <el-progress :text-inside="true" :stroke-width="24" v-show="Math.floor(scope.row.rate)>=50" :percentage="Math.floor(scope.row.rate)">
                </el-progress>
                <el-progress :text-inside="true" :stroke-width="24" v-show="Math.floor(scope.row.rate)<50" :percentage="Math.floor(scope.row.rate)" status="exception">
                </el-progress>
              </template>
            </el-table-column>
          </el-table>
          <el-table :data="info.list" v-loading="loading" border key="father" v-if="flag===2" max-height="450" width='1024'>
            <el-table-column prop="useCompany" label="使用单位名称" width="300">
              <template slot="header">
                <span>使用单位名称</span>
                <font-awesome-icon :icon="['fas', 'arrow-left']" style="color:#3699FF;font-size:14px" @click="back" />
              </template>
            </el-table-column>
            <el-table-column prop="totalNum" label="落实进度" width="300">
              <template slot-scope="scope">
                <el-progress :text-inside="true" :stroke-width="24" v-show="Math.floor(scope.row.rate)>=50" :percentage="Math.floor(scope.row.rate)">
                </el-progress>
                <el-progress :text-inside="true" :stroke-width="24" v-show="Math.floor(scope.row.rate)<50" :percentage="Math.floor(scope.row.rate)" status="exception">
                </el-progress>
              </template>
            </el-table-column>
            <el-table-column prop="score" label="综合得分" min-width="90">
            </el-table-column>
            <el-table-column prop="safetyOrganization" label="安全管理机构" min-width="110">
              <template slot-scope="scope">
                <span :style="scope.row.safetyOrganization&&scope.row.safetyOrganization!=='0'?'color:green!important' :'color:red!important;'">{{scope.row.safetyOrganization&&scope.row.safetyOrganization!=='0'?'已完成' :'未完成'}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="safetyManager" label="安全负责人" min-width="100">
              <template slot-scope="scope">
                <span :style="scope.row.safetyManager&&scope.row.safetyManager!=='0'?'color:green!important' :'color:red!important;'">{{scope.row.safetyManager&&scope.row.safetyManager!=='0'?'已完成' :'未完成'}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="unfinishNum" label="安全管理制度" min-width="110">
              <template slot-scope="scope">
                <span :style="scope.row.safetyManagement&&scope.row.safetyManagement!=='0'?'color:green!important' :'color:red!important;'">{{scope.row.safetyManagement&&scope.row.safetyManagement!=='0'?'已完成' :'未完成'}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="postResponsibility" label="岗位安全责任制度" min-width="140">
              <template slot-scope="scope">
                <span :style="scope.row.postResponsibility&&scope.row.postResponsibility!=='0'?'color:green!important' :'color:red!important;'">{{scope.row.postResponsibility&&scope.row.postResponsibility!=='0'?'已完成' :'未完成'}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="emergencyPlan" label="应急专项预案" min-width="120">
              <template slot-scope="scope">
                <span :style="scope.row.emergencyPlan&&scope.row.emergencyPlan!=='0'?'color:green!important' :'color:red!important;'">{{scope.row.emergencyPlan&&scope.row.emergencyPlan!=='0'?'已完成' :'未完成'}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="emergencyDrill" label="应急演练记录" min-width="110">
              <template slot-scope="scope">
                <span :style="scope.row.emergencyDrill&&scope.row.emergencyDrill!=='0'?'color:green!important' :'color:red!important;'">{{scope.row.emergencyDrill&&scope.row.emergencyDrill!=='0'?'已完成' :'未完成'}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="operationRules" label="操作规程" min-width="90">
              <template slot-scope="scope">
                <span :style="scope.row.operationRules&&scope.row.operationRules!=='0'?'color:green!important' :'color:red!important;'">{{scope.row.operationRules&&scope.row.operationRules!=='0'?'已完成' :'未完成'}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="trainRecord" label="培训记录" min-width="90">
              <template slot-scope="scope">
                <span :style="scope.row.trainRecord&&scope.row.trainRecord!=='0'?'color:green!important' :'color:red!important;'">{{scope.row.trainRecord&&scope.row.trainRecord!=='0'?'已完成' :'未完成'}}</span>
              </template>
            </el-table-column>
          </el-table>
        </transition>
        <!-- <my-pagination v-show="total > 0" :total="total" v-if="flag === 3" :page.sync="queryParams.pageNum" :limit.sync="queryParams.pageSize" @pagination="getList" /> -->
      </el-col>
    </el-row>
    <div class="el-login-footer">
      <a href="https://beian.miit.gov.cn/#/Integrated/index">苏ICP备20001396号</a>
      <div>Copyright ©
        2019-2021 Imtenrent. All Rights Reserved.</div>
    </div>
  </div>
</template>

<script>
import {
  getAllList, getItemList
} from "@/api/newpostcode.js";

export default {
  name: "postCode",
  components: {},
  data() {
    return {
      info: {},
      flag: 1,
      useCompany: "",
      supervisoryOrgan: "",
      queryParams: {
        useCompany: "",
        pageNum: 1,
        pageSize: 10
      },
      total: 0,
      loading: false,
      searchKey: '',
      // 一级列表点击获取的名称
      firstKey: ''
    };
  },
  computed: {
  },
  mounted() {
    this.getAllList();
  },
  methods: {
    // 设置单元格颜色
    myclass({ row, column, rowIndex, columnIndex }) {

    },
    getAllList() {
      this.loading = true;
      getAllList().then(response => {
        this.info = response.data.data
        this.loading = false;
      })
    },
    // 点击获取分局下子列表
    getItemList(row) {
      // var myDate = new Date(); //实例一个时间对象； 
      // myDate.getFullYear();   //获取系统的年； 
      // myDate.getMonth() + 1;   //获取系统月份，由于月份是从0开始计算，所以要加1 
      // myDate.getDate(); // 获取系统日， 
      // myDate.getHours(); //获取系统时， 
      // myDate.getMinutes(); //分 
      // myDate.getSeconds(); //秒
      // console.log(myDate.getHours(), 1)
      // console.log(myDate.getMinutes(), 2)
      // console.log(myDate.getSeconds(), 3)
      // console.log(new Date().getTime())
      this.loading = true;
      this.flag = 2
      this.firstKey = row.supervisoryOrgan
      getItemList({
        supervisoryOrgan: row.supervisoryOrgan
      }).then(response => {
        if (response.data.code == 200) {
          this.info = response.data.data
          this.loading = false;
        }
        // var myDate = new Date(); //实例一个时间对象； 
        // myDate.getFullYear();   //获取系统的年； 
        // myDate.getMonth() + 1;   //获取系统月份，由于月份是从0开始计算，所以要加1 
        // myDate.getDate(); // 获取系统日， 
        // myDate.getHours(); //获取系统时， 
        // myDate.getMinutes(); //分 
        // myDate.getSeconds(); //秒
        // console.log(myDate.getHours(), 1)
        // console.log(myDate.getMinutes(), 2)
        // console.log(myDate.getSeconds(), 3)
        // console.log(new Date().getTime())
      })
    },
    clickSearch() {
      this.loading = true;
      getItemList({
        supervisoryOrgan: this.firstKey,
        useCompany: this.searchKey,
      }).then(response => {
        this.info = response.data.data
        this.loading = false;
      })
    },
    // 返回上一级
    back() {
      --this.flag;
      this.getAllList()
    }
  }
}
</script>

<style lang="scss">
html,
body #app {
  padding-bottom: 150px;
}
@media screen and (max-width: 768px) {
  .home-bg {
    height: 100vw;
    background: url(~@/assets/images/bg_index.png) no-repeat center;
    .home-particles {
      height: 26vw;
    }
    .home-title {
      font-family: YouSheBiaoTiHei;
      font-size: 5vw;
      text-align: center;
      height: 26vw;
      line-height: 26vw;
      transform: translateY(-26vw);
    }
  }
  .home-center {
    width: 62vw;
    height: 14vw;
    background: #ffffff;
    border-radius: 5px;
    box-shadow: 0 0 10px #888888;
    margin: 0 auto;
    margin-top: -4vw;
    display: flex;
    justify-content: space-around;
    align-items: center;
    .one-unit {
      width: 18vw;
      height: 6vw;
      display: flex;
      color: #4f81c7;
      .unit-img {
        width: 6vw;
        height: 6vw;
      }
      .unit-img-1 {
        background: url(~@/assets/img/1.png) no-repeat center/100%;
      }
      .unit-img-2 {
        background: url(~@/assets/img/2.png) no-repeat center/100%;
      }
      .unit-img-3 {
        background: url(~@/assets/img/3.png) no-repeat center/100%;
      }
      .unit-text {
        width: 12vw;
        height: 6vw;
        .num {
          font-size: 2.5vw;
          font-family: pangMenZhengDao;
          text-align: center;
          height: 3vw;
          line-height: 3vw;
        }
        .info {
          font-size: 2.2vw;
          text-align: center;
          height: 3vw;
          line-height: 3vw;
          color: #999999;
          white-space: nowrap;
        }
      }
    }
  }
}
@media screen and (min-width: 768px) {
  .home-bg {
    height: 12vw;
    background: url(~@/assets/images/bg_index.png) no-repeat center/100% 100%;
    .home-particles {
      height: 12vw;
    }
    .home-title {
      font-family: YouSheBiaoTiHei;
      font-size: 3.5vw;
      text-align: center;
      height: 12vw;
      line-height: 12vw;
      transform: translateY(-12vw);
    }
  }
  .home-center {
    width: 60vw;
    height: 8vw;
    background: #ffffff;
    border-radius: 5px;
    box-shadow: 0 0 10px #888888;
    margin: 0 auto;
    margin-top: -3vw;
    display: flex;
    justify-content: space-around;
    align-items: center;
    .one-unit {
      width: 12vw;
      height: 4vw;
      display: flex;
      color: #4f81c7;
      .unit-img {
        width: 4vw;
        height: 4vw;
      }
      .unit-img-1 {
        background: url(~@/assets/img/1.png) no-repeat center/100%;
      }
      .unit-img-2 {
        background: url(~@/assets/img/2.png) no-repeat center/100%;
      }
      .unit-img-3 {
        background: url(~@/assets/img/3.png) no-repeat center/100%;
      }
      .unit-text {
        width: 8vw;
        height: 4vw;
        .num {
          font-size: 1.5vw;
          font-family: pangMenZhengDao;
          text-align: center;
          height: 2vw;
          line-height: 2vw;
        }
        .info {
          font-size: 1.2vw;
          text-align: center;
          height: 2vw;
          line-height: 2vw;
          color: #999999;
          white-space: nowrap;
        }
      }
    }
  }
}
.el-login-footer {
  height: 40px;
  line-height: 20px;
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
  color: rgb(184, 48, 48);
  font-family: Arial;
  font-size: 12px;
  letter-spacing: 1px;
}
.home-query {
  margin: 10px 0;
}
.home-table {
  margin: 10px 0;
}
.useCompany-pic {
  display: flex;
  .new-pic {
    width: 30px;
    height: 30px;
    background: {
      image: url('~@/assets/img/new.png');
      repeat: no-repeat;
      size: 100%;
    }
  }
}
.setclass {
  background: rgba(26, 160, 148, 0.05);
}
// ::v-deep .el-table {
//   .el-table__fixed {
//     height: auto !important; // 此处的important表示优先于element.style
//     bottom: 17px; // 改为自动高度后，设置与父容器的底部距离，则高度会动态改变
//   }
// }

// //给固定列设置下边距
// ::v-deep .el-table {
//   .el-table__fixed {
//     height: auto !important;
//     bottom: 8px !important; //具体值是多少根据你横向滚动条的高度进行设置
//   }
// }
// ::v-deep .el-table__body-wrapper {
//   z-index: 2;
// }
// //去掉固定列下方的横线
// ::v-deep .el-table__fixed::before,
// .el-table__fixed-right::before {
//   display: none;
// }
</style>