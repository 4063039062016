import request from "@/utils/request";

//获取所有数据
export function selectSignInfo() {
  return request({
    url: "/tsg/baseInfo/tsgSignTrain/reportSignInfoList",
    method: "get"
  });
}

//通过所属分局
export function getBySupervisoryOrgan(query) {
  return request({
    url: "/tsg/baseInfo/tsgSignTrain/reportBySupervisoryOrgan",
    method: "get",
    params: query
  });
}

// 查询签约培训详细
export function getTrain(query) {
  return request({
    url: "/tsg/baseInfo/tsgSignTrain/reportBySignId",
    method: "get",
    params: query
  });
}

// 查询签约培训详细依据企业名称
export function selectByUseCompany(query) {
  return request({
    url: "/tsg/baseInfo/tsgSignTrain/reportByUseCompany",
    method: "get",
    params: query
  });
}
