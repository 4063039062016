import Vue from "vue";
import VueRouter from "vue-router";
import postCode from "../views/postCode.vue";
import NewPostCode from "../views/NewPostCode.vue";
import SignStatistics from "../views/signStatistics.vue";
import MaintenanceStatistics from "../views/maintenanceStatistics.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "postCode",
    component: postCode
  },
  {
    path: "/newPostCode",
    name: "NewPostCode",
    component: NewPostCode
  },
  {
    path: "/signStatistics",
    name: "signStatistics",
    component: SignStatistics
  },
  {
    path: "/maintenanceStatistics",
    name: "maintenanceStatistics",
    component: MaintenanceStatistics
  }
  //   {
  //     path: "/about",
  //     name: "About",
  //     // route level code-splitting
  //     // this generates a separate chunk (about.[hash].js) for this route
  //     // which is lazy-loaded when the route is visited.
  //     component: () =>
  //       import(/* webpackChunkName: "about" */ "../views/About.vue"),
  //   },
];

const router = new VueRouter({
  routes
});

export default router;
