<template>
  <div class="ribbon-box">
    <div class="ribbon-content">
      <div class="main-grid">
        <div :class="`${classChosen()} ribbon`">{{title}}</div>
        <slot name="main" class="main"></slot>
      </div>
      <div class="assistant-grid">
        <slot name="assistant-grid"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Ribbon",
  props: {
    title: {
      type: String,
      default: "标题"
    },
    color: {
      type: String,
      default: "primary"
    },
  },
  data() {
    return {

    }
  },
  methods: {
    classChosen() {
      switch (this.color) {
        case "info": return "ribbon-info";
        case "primary": return "ribbon-primary";
        case "dark": return "ribbon-dark";
        case "success": return "ribbon-success";
        case "warning": return "ribbon-warning";
        case "danger": return "ribbon-danger";
        case "pink": return "ribbon-pink";
      }
    }
  }
}
</script>



<style lang="scss" scoped>
.ribbon-box {
  border-radius: 4px;
  margin-bottom: 10px;
  background: #fff;
  .ribbon {
    width: 140px;
    height: 35px;
    line-height: 35px;
    position: relative;
    text-align: center;
    margin-bottom: 15px;
    margin-left: -30px;
    box-shadow: 2px 5px 10px rgba(65, 77, 95, 0.15);
    border-radius: 0 3px 3px 0;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
  }
  .ribbon:before {
    content: " ";
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 0;
    height: 0;
    border-top: 10px solid red;
    border-left: 10px solid transparent;
  }
  .ribbon-primary {
    background: #188ae2;
  }
  .ribbon-primary:before {
    border-color: #136eb4 transparent transparent;
  }
  .ribbon-dark {
    background: #414d5f;
  }
  .ribbon-dark:before {
    border-color: #222832 transparent transparent;
  }
  .ribbon-info {
    background: #35b8e0;
  }
  .ribbon-info:before {
    border-color: #1b8bae transparent transparent;
  }
  .ribbon-success {
    background: #31ce77;
  }
  .ribbon-success:before {
    border-color: #229053 transparent transparent;
  }
  .ribbon-warning {
    background: #fbcc5c;
  }
  .ribbon-warning:before {
    border-color: #f9b617 transparent transparent;
  }
  .ribbon-danger {
    background: #f34943;
  }
  .ribbon-danger:before {
    border-color: #db150e transparent transparent;
  }

  .ribbon-pink {
    background: #ff679b;
  }
  .ribbon-pink:before {
    border-color: #af466b transparent transparent;
  }
}
</style>
