<template>
  <div class="self-card-box">
    <div class="main-grid">
      <div class="title">
        <span class="icon">
          <slot name="icon"></slot>
        </span>
        <span>
          <slot name="title"></slot>
        </span>
      </div>
      <div class="main">
        <slot name="main" class="main"></slot>
      </div>
    </div>
    <div class="assistant-grid">
      <slot name="assistant-grid"></slot>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss">
.self-card-box {
  margin-bottom: 10px;
  background: #ffffff;
  border: 1px solid #e6ebf5;
  border-radius: 4px;
  display: flex;
  .main-grid {
    width: 85%;
    padding: 20px;
    .title {
      width: 100%;
      font-size: 18px;
      font-weight: 700;
      text-align: LEFT;
      color: #333333;
      .icon {
        display: inline-block;
        color: #bdbdbd;
        width: 10%;
        text-align: right;
      }
    }
  }
  .assistant-grid {
    width: 15%;
    padding: 20px;
    border-left: 1px solid #e6ebf5;
    text-align: center;
  }
}
</style>
