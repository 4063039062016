<template>
  <el-dialog
    class="applicationDialog"
    title="认证数据"
    :visible.sync="open"
    fullscreen
    append-to-body
  >
    <el-tabs v-model="activeName">
      <el-tab-pane label="认证信息" name="first">
        <ribbon title="设备信息" class="changeInfo" color="success">
          <template v-slot:main>
            <div v-loading="loading" v-if="form.equipments.length > 0">
              <el-table
                v-loading="loading"
                :data="form.equipments"
                :row-style="rowStyle"
              >
                <el-table-column
                  label="档案号"
                  width="150"
                  align="center"
                  prop="fileNum"
                />
                <el-table-column
                  label="所属分局"
                  width="250"
                  align="center"
                  prop="supervisoryOrgan"
                />
                <el-table-column
                  label="车牌号"
                  align="center"
                  prop="licenseNo"
                  :show-overflow-tooltip="true"
                />
                <el-table-column
                  label="是否认证"
                  align="center"
                  width="80"
                  prop="isSign"
                >
                  <template slot-scope="scope">
                    {{ scope.row.isSign == 1 ? "是" : "否" }}
                  </template>
                </el-table-column>
                <el-table-column
                  label="是否安装"
                  align="center"
                  width="80"
                  prop="isInstall"
                >
                  <template slot-scope="scope">
                    {{ scope.row.isInstall == 1 ? "是" : "否" }}
                  </template>
                </el-table-column>
                <el-table-column
                  label="安装日期"
                  align="center"
                  prop="installDate"
                />
                <el-table-column
                  label="终端编号"
                  align="center"
                  prop="terminalNum"
                  :show-overflow-tooltip="true"
                />
                <el-table-column
                  label="备注"
                  align="center"
                  width="250"
                  prop="remark"
                  :show-overflow-tooltip="true"
                />
              </el-table>
            </div>
            <no-data-list v-loading="loading" v-else></no-data-list>
            <my-pagination
              v-show="equipmentTotal > 0"
              :total="equipmentTotal"
              :page.sync="equipmentParams.pageNum"
              :limit.sync="equipmentParams.pageSize"
              @pagination="getList"
            />
          </template>
        </ribbon>
      </el-tab-pane>
    </el-tabs>
  </el-dialog>
</template>

<script>
import tsg from "@/views/mixins/tsg.js";
import { getTrain, selectByUseCompany } from "@/api/signStatistics.js";
export default {
  name: "applyDialog",
  mixins: [tsg],
  data() {
    return {
      // 是否显示弹出层
      open: false,
      // 表单参数
      form: {
        signId: "",
        signCompany: "",
        supervisoryOrgan: "",
        signNum: "",
        signDate: "",
        trainEndDate: "",
        installDate: null,
        installNum: "",
        notInstallNum: "",
        installSituation: "",
        equipments: []
      },
      loading: false,
      activeName: "first",
      flag: false,
      equipmentParams: {
        pageNum: 1,
        pageSize: 5
      },
      equipmentTotal: 0,
      dataList: [],
      tempList: [],
      queryParam: {},
      useCompanyParam: {}
    };
  },

  methods: {
    rowStyle(row) {
      let that = row.row;
      let stylejson = {};
      if (!that.fileNumExist) {
        stylejson.color = "orange";
        return stylejson;
      } else {
        return "";
      }
    },
    // 打开弹窗
    openDialog(signId, useCompany) {
      this.reset();
      if (signId) {
        this.queryParam.signId = signId;
        getTrain(this.queryParam).then(response => {
          let result = response.data;
          if (result.code === 200) {
            this.form = result.data;
            this.equipmentTotal = this.form.equipments.length;
            this.dataList = this.form.equipments;
            this.tempList = this.dataList.filter(
              (val, index) =>
                index >=
                  (this.equipmentParams.pageNum - 1) *
                    this.equipmentParams.pageSize &&
                index <
                  this.equipmentParams.pageNum * this.equipmentParams.pageSize
            );
            this.form.equipments = this.tempList;
          }
        });
      } else {
        this.useCompanyParam.useCompany = useCompany;
        selectByUseCompany(this.useCompanyParam).then(response => {
          let result = response.data;
          if (result.code === 200) {
            this.form = result.data;
            this.equipmentTotal = result.data.equipments.length;
            this.dataList = result.data.equipments;
            this.tempList = this.dataList.filter(
              (val, index) =>
                index >=
                  (this.equipmentParams.pageNum - 1) *
                    this.equipmentParams.pageSize &&
                index <
                  this.equipmentParams.pageNum * this.equipmentParams.pageSize
            );
            this.form.equipments = this.tempList;
          }
        });
      }
      this.open = true;
    },
    getList() {
      this.tempList = this.dataList.filter(
        (val, index) =>
          index >=
            (this.equipmentParams.pageNum - 1) *
              this.equipmentParams.pageSize &&
          index < this.equipmentParams.pageNum * this.equipmentParams.pageSize
      );
      this.form.equipments = this.tempList;
    },
    // 关闭弹窗
    closeDialog() {
      this.reset();
      this.$parent.handleQuery();
      this.open = false;
    },
    // 表单重置
    reset() {
      this.form = {
        signId: "",
        signCompany: "",
        supervisoryOrgan: "",
        signNum: "",
        signDate: "",
        trainEndDate: "",
        installDate: null,
        installNum: "",
        notInstallNum: "",
        installSituation: "",
        equipments: []
      };
      this.equipmentParams = {
        pageNum: 1,
        pageSize: 5
      };
    }
  }
};
</script>

<style lang="scss">
.center-top {
  display: flex;
  justify-content: center;
  .num1 {
    width: 2.5vw;
    height: 2.5vw;
    line-height: 2vw;
    border-radius: 50%;
    background: #146dbf;
    border: 0.25vw solid #bbdbf5;
    font-size: 20px;
    font-weight: 700;
    text-align: CENTER;
    color: #ffffff;
  }
  .spec {
    background: #67c23a;
  }
  .line {
    width: 7.5vw;
    height: 0.25vw;
    background: #bbdbf5;
    margin-top: 1.25vw;
  }
}
.center-bottom {
  display: flex;
  justify-content: center;
  .info1 {
    width: 10vw;
    height: 2.5vw;
    line-height: 2vw;
    font-size: 14px;
    font-weight: 400;
    text-align: CENTER;
    color: #545454;
  }
}
.register .el-select {
  width: 100%;
}

.applicationDialog {
  .el-dialog {
    background: #f3f4f6 !important;
  }
}
</style>
