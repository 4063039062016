import request from '@/utils/request'

// 获取所有分局列表
export function getAllList() {
    return request({
        url: 'tsg/baseInfo/mainPage/doublePrevention',
        method: 'get'
    });
}
// 获取分局下子列表
export function getItemList(data) {
    return request({
        url: 'tsg/baseInfo/mainPage/doublePrevention/supervisoryOrgan',
        method: 'get',
        params: data
    });
}